import React, { Suspense } from 'react';
import { SHARED_METRICS_ID } from 'app/constants/SettingsConstants';
import Spinner from 'app/components/SpinnerComponent';
import {
	MONITOR_CITY_CINEMA_ID,
	MONITOR_KINOSTAR_CINEMA_ID, MONITOR_SBS_CINEMA_ID,
} from 'app/constants/CinemaConstants';
import { Moment } from 'moment';
import { IRelease, Qualifiers } from 'app/types/releases';
import { FILTER_PANEL_ELEMENTS, FiltersOfReleasesList, SortTypes } from 'app/modules/filters/types';
import { Cinema, CustomPage, GetContrastColorForAcccent } from 'app/types/common';
import * as COLORS from 'css/colors.module.scss';
import { BRIGHTNESS_MIDDLE_VALUE, SUPPORTED_LANGS, __DEV__ } from 'app/constants/AppConstants';

export const convertToRubles = sum => (sum ? sum / 100 : 0);
export const getTitles = arrayOfObjects => _.pluck(arrayOfObjects, 'title').join(', ');
export const formatMoment = (day: Moment | null) => day?.format("YYYY-MM-DD") || '';

export const normalizeResponseData = <T extends {name: string, value: unknown}>(arrayOfObjects: T[]) =>
	arrayOfObjects.reduce((memo, object) => {
		memo[object.name] = object.value;

		return memo;
}, {} as {
	[name: string]: T["value"]
});

export const setYaReachGoal = (
	goalId: string,
	params: object | null = null,
	isPublicEvent: boolean = true,
	recursiveCallsCount = 1
) => {
	if (__DEV__ || recursiveCallsCount > 15) {
		window[`yaCounter${SHARED_METRICS_ID}`]?.reachGoal(goalId, params);

		return;
	}

	let counterIDs: Array<number | string> = [];

	if (isPublicEvent && window.currentYandexCounters ) {
		counterIDs = Array.from(window.currentYandexCounters)
	};

	counterIDs.push(SHARED_METRICS_ID);

	if (counterIDs.some((id) => !window[`yaCounter${id}`])) {
		setTimeout(() => setYaReachGoal(goalId, params, isPublicEvent, recursiveCallsCount + 1), 500);
	}

	counterIDs.forEach(id => {
		window[`yaCounter${id}`]?.reachGoal(goalId, params);
	});
};

export const loadComponent = <T extends React.ElementType>(Component: T) => (props: React.ComponentProps<T>) => (
	<Suspense fallback={<Spinner />}>
		<Component {...props} />
	</Suspense>
);

export const scrollToTop = () => {
	const rect = document.getElementsByClassName('menu--navigation')[0].getBoundingClientRect();
	const headerContainer = document.getElementById('header');

	if (rect.y < 0 && headerContainer) {
		headerContainer.scrollIntoView();
	}
};

export const sortMonitorCinemas = <T extends {id: number}>(cinemas: T[]): T[] =>
	[...cinemas].sort((first, second) => {
		if (first.id === MONITOR_SBS_CINEMA_ID || second.id === MONITOR_CITY_CINEMA_ID) return -1;
		if (first.id === MONITOR_CITY_CINEMA_ID || second.id === MONITOR_SBS_CINEMA_ID) return 1;

		return 0;
	},
);

export const parsePhone = (phoneString: string) => phoneString.match(/^\+?\d|\d+/g)?.join('') ?? '';

export const getIsWidgetDisabledInSpecificCinema = (cinemaId) => [MONITOR_KINOSTAR_CINEMA_ID].includes(cinemaId);

export const transformMenuLinks: (links: CustomPage[], prefix: number | string) => CustomPage[] = (links, prefix) => links.map(link => ({
	...link,
	href: `/${prefix}${link.href}`,
	sub: link.sub && transformMenuLinks(link.sub, prefix),
}));

export const getDateFromSearch = (searchString: string) => {
	const parameters = _.object<{[key: string]: string}>(searchString.slice(1).split('&').map(item => item.split('=')));

	if (parameters.date) {
		const pattern = parameters.date.match(/\d{4}-\d{2}-\d{2}/);

		return pattern ? pattern[0] : null;
	}

	return null;
};

export const calcVideoFrameSize = (figureNode: HTMLElement) => {
	const iframe = figureNode.querySelector<HTMLIFrameElement>('iframe');

	if (!iframe || !figureNode.style.width.includes("px") || !figureNode.style.height.includes("px")) return;

	const initialWidth = parseInt(figureNode.style.width);
	const initialHeight = parseInt(figureNode.style.height);

	if (initialWidth && initialHeight && figureNode.offsetWidth < initialWidth) {
		const newHeight = initialHeight / initialWidth * figureNode.offsetWidth;

		figureNode.style.height = `${newHeight}px`;
		iframe.style.height = `${newHeight}px`;
	}
}

// берем общие настройки для всех кинотеатров в гододе
// так как могут быть кейсы когда в одном кинотеатре включениы настройки, а в дрогом нет
export const getDisplaySettingsOfCinemasForCurrentCity = (cinemas: Cinema[], currentCityId: number) => {
	const settingsCinemasOfCurrentCity = cinemas.reduce((acc, cinema) => {
		if (cinema.city_id === currentCityId) {
			const sectionDisplaySettings = acc.section_display_settings;
			const currentSectionDisplaySettings = cinema.display_settings.section_display_settings;

			acc = {
				...acc,
				section_display_settings: {
					imax: sectionDisplaySettings.imax || currentSectionDisplaySettings.imax,
					vip: sectionDisplaySettings.vip || currentSectionDisplaySettings.vip,
					pro_culture: sectionDisplaySettings.vip || currentSectionDisplaySettings.vip,
					not_movie: sectionDisplaySettings.not_movie || currentSectionDisplaySettings.not_movie,
				}
			};
		}

		return acc;
	}, cinemas[0].display_settings);

	return settingsCinemasOfCurrentCity;
}

export const isProCultureRelease = (release: IRelease) => release.all_qualifiers?.includes(Qualifiers.pro_culture);

const checkAgeRating = (release: IRelease, filters:FiltersOfReleasesList) =>
	!filters[FILTER_PANEL_ELEMENTS.AGE] || filters[FILTER_PANEL_ELEMENTS.AGE]?.[release.age_rating];

const checkGenres = (release: IRelease, filters: FiltersOfReleasesList) =>
	!filters[FILTER_PANEL_ELEMENTS.GENRE] || release.genres.some(({id}) => filters[FILTER_PANEL_ELEMENTS.GENRE]?.[id]);

const checkFeatures = (release: IRelease, filters: FiltersOfReleasesList) => (
	!filters[FILTER_PANEL_ELEMENTS.FEATURES] ||
	release.formats.some(({id}) => filters[FILTER_PANEL_ELEMENTS.FEATURES]?.[id]) ||
	release.all_qualifiers.some((qualifier) => filters[FILTER_PANEL_ELEMENTS.FEATURES]?.[qualifier])
);

export const filterReleases = (releases: IRelease[], filters: FiltersOfReleasesList) => {
	const filteredReleases: IRelease[] = [];

	releases.forEach((release) => {
		const isGood =
			(!filters[FILTER_PANEL_ELEMENTS.PUSHKIN_CARD] || isProCultureRelease(release)) &&
			checkAgeRating(release, filters) &&
			checkGenres(release, filters) &&
			checkFeatures(release, filters)

		isGood && filteredReleases.push(release);
	});

	return filteredReleases;
}

export const sortReleases = (filteredReleases: IRelease[], currentSortType: SortTypes) => {
	switch (currentSortType) {
		case SortTypes.CLOSEST_SEANCES:
			return [...filteredReleases].sort((a, b) => {
				const minUnixA = a.seances.reduce((acc, {start_date_time}) => {
					return Math.min(acc, moment(start_date_time).unix())
				}, Infinity);

				const minUnixB = b.seances.reduce((acc, {start_date_time}) => {
					return Math.min(acc, moment(start_date_time).unix())
				}, Infinity);

				return minUnixA - minUnixB;
			});
		case SortTypes.PRICE:
			return [...filteredReleases].sort((a, b) => {
				let minA = Infinity;
				let minB = Infinity;

				a.seances.forEach(({price: {min}}) => {minA = Math.min(minA, min)});
				b.seances.forEach(({price: {min}}) => {minB = Math.min(minB, min)});

				return minA - minB;
			});
		default:
			return filteredReleases;
	}
}

const style = document.documentElement.style;
const DEFAULT_COLOR = COLORS.gray100;
const ALTERNATIVE_COLOR = COLORS.white;
export const getContrastColorForAcccent = _.memoize((defaultColor = DEFAULT_COLOR, alternativeColor = ALTERNATIVE_COLOR) => {
	const accentColor = style.getPropertyValue('--accent').split(', ');
	const redValue = parseInt(accentColor[0]);
	const greenValue = parseInt(accentColor[1]);
	const blueValue = parseInt(accentColor[2]);

	// Color brightness is determined by the following formula:
	// (Red value * 0.299) + (Green value * 0.587) + (Blue value * 0.114)
	// 125 this is the middle value between black and white

	return redValue * 0.299 + greenValue * 0.587 + blueValue * 0.114 >= BRIGHTNESS_MIDDLE_VALUE ? defaultColor : alternativeColor;
}) as GetContrastColorForAcccent;

export const convertMinutesToString = (minutes: number): string => {
	const hours = Math.floor(minutes / 60);
	const minutesRemaining = minutes % 60;

	const hoursStr = hours > 0 ? `${hours} ${i18n.t('Time.hour', {count: hours})}` : '';
	const minutesStr = minutesRemaining > 0 ? `${minutesRemaining} ${i18n.t('Time.minute', {count: minutesRemaining})}` : '';

	return hoursStr ? `${hoursStr} ${minutesStr}` : minutesStr;
};

export const getReleaseAllQualifiersMap = (allQualifiers: Qualifiers[]) => {
	const hasQualifier = (qualifier: string) => (allQualifiers || []).some(value => value === qualifier);

	const isPremiere = hasQualifier(Qualifiers.premiere);
	const isClosedShow = hasQualifier(Qualifiers.closed_show);
	const isStellarPremiere = hasQualifier(Qualifiers.stellar_premiere);
	const isSpecialShow = hasQualifier(Qualifiers.special_show);
	const isForKids = hasQualifier(Qualifiers.for_kids);

	return {
		isPremiere: ![isClosedShow, isStellarPremiere, isSpecialShow].includes(true) && isPremiere,
		isClosedShow,
		isStellarPremiere,
		isSpecialShow,
		isForKids
	};
}

export const getCinemaLangsOptions = () => SUPPORTED_LANGS?.map(value => ({
	title: i18n.t(`lang.${value}`),
	value,
}));
